import React from 'react';
import Loadable from 'react-loadable';

function reload() {
    window.location.reload();
}

function Loading({ error, pastDelay }) {
    if (error) {
        console.log(error);
        return (
            <div>
                Hata {error.message}
                <button type="button" onClick={reload}>
                    Tekrar dene
                </button>
            </div>
        );
    }
    if (pastDelay) {
        return <div>Yükleniyor...</div>;
    }
    return null;
}

export default function lazyImport(module) {
    return Loadable({
        loader: () => module,
        loading: Loading,
        delay: 200
    });
}
