import { Compose24Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function HeaderComposeButton() {
  const history = useHistory();

  const onClick = () => {
    history.push('/');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('mobile header');
    dispatch({
      type: 'SET_CURRENT_SCREEN_MODE',
      payload: { screenMode: 'mobile' },
    });
    return () => {
      // console.log('mobile header unmount');
      dispatch({
        type: 'SET_CURRENT_SCREEN_MODE',
        payload: { screenMode: 'desktop' },
      });
    };
  }, []);

  return (
    <button type="button" className="px-3" onClick={onClick}>
      <Compose24Regular />
    </button>
  );
}
