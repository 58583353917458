/* eslint-disable import/no-anonymous-default-export */

import { combineReducers } from 'redux';
import botsReducer from './botsReducer';
import chatsReducer from './chatsReducer';
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
    bots: botsReducer,
    chats: chatsReducer,
    settings: settingsReducer,
    auth: authReducer
});

export default rootReducer;
