import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAppAuthentication from '../../helpers/useAppAuthentication';

const LoginRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = useAppAuthentication('loginroute');
  //const isSignedIn = true;
  // console.log('LoginRoute', { isSignedIn, ...rest });
  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (isSignedIn) {
            return <Redirect exact to="/" />;
          }
          return <Component {...props} />;
        }}
      />
    </div>
  );
};

export default LoginRoute;
