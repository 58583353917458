/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import MobileHeader from './mobile-header';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useMount, useSetState } from 'react-use';
import { joyRideSteps } from '../../helpers/joyride-steps';
import LeftPanel from './left-panel';
import PrivateRoute from '../routes/private-route';
import lazyImport from '../../helpers/lazyImport';
import { useSelector, useDispatch } from 'react-redux';

const ChatPanel = lazyImport(import('./chat-panel'));
//import ChatPanel from './chat-panel';

export default function Main() {
  const showProductTour = useSelector((state) => {
    return state.settings.showProductTour === undefined
      ? true
      : state.settings.showProductTour;
  });

  // console.log('showProductTour', showProductTour);

  const dispatch = useDispatch();

  const [{ run, steps }, setState] = useSetState({
    run: showProductTour,
    steps: joyRideSteps,
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [
      STATUS.FINISHED,
      STATUS.SKIPPED,
    ];

    console.log('handleJoyrideCallback', { status, type });
    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      dispatch({ type: 'SET_PRODUCT_TOUR_SHOWN' });
    }
  };

  useMount(() => {
    setTimeout(() => {
      setState({ run: showProductTour, steps: joyRideSteps });
    }, 1000);
  });

  return (
    <div className="App">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        locale={{
          back: 'Geri',
          close: 'Kapat',
          next: 'Sonraki',
          skip: 'Atla',
          last: 'Bitti',
        }}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#BC6F00',
          },
        }}
      />
      <div className="relative z-0 flex h-full w-full overflow-hidden">
        <div
          className="flex-shrink-0 overflow-x-hidden bg-token-sidebar-surface-primary hidden md:block"
          style={{ width: 440 }}
        >
          <LeftPanel />
        </div>
        <div
          className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden"
          style={{ boxShadow: '13px 1px 20px 4px #938888' }}
        >
          <main className="relative h-full w-full flex-1 overflow-auto transition-width">
            <MobileHeader />
            <Switch>
              <Redirect exact from="/" to="home" />
              <PrivateRoute
                path="/home"
                name="Home"
                component={ChatPanel}
              />
              <PrivateRoute
                path="/chat/:chatId"
                name="Chat Panel"
                component={ChatPanel}
              />
            </Switch>
          </main>
        </div>
      </div>
    </div>
  );
}
