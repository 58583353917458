import React from 'react';
import { Tree } from '@fluentui/react-components';
import ChatTimeHistoryChatItem from './chat-time-history-chat-item';

export default function ChatTimeHistory({
  chatTimeGroups,
  currentChatId,
  navigateToChat,
  navigateToHome,
}) {
  // console.log('ChatTimeHistory', chatTimeGroups);

  return (
    <div className="mt-4">
      <div>
        {Object.keys(chatTimeGroups).map((timeGroup) => {
          const timeGroupChats = chatTimeGroups[timeGroup];
          return (
            <ChatTimeHistoryChatItem
              key={timeGroup}
              timeGroup={timeGroup}
              chats={timeGroupChats}
              currentChatId={currentChatId}
              navigateToChat={navigateToChat}
              navigateToHome={navigateToHome}
            />
          );
        })}
      </div>
    </div>
  );
}
