import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAppAuthentication from '../../helpers/useAppAuthentication';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = useAppAuthentication('privateroute');
  //const isSignedIn = true;

  // console.log('PrivateRoute', { isSignedIn, ...rest });
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn) {
          return <Component {...props} />;
        }
        return <Redirect exact from="/" to="/login" />;
      }}
    />
  );
};

export default PrivateRoute;
