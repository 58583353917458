// https://fluentsite.z22.web.core.windows.net/quick-start
import { Route, BrowserRouter } from 'react-router-dom';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { TeamsFxContext } from './Context';
import StateProvider from './../store/store';
import config from './../lib/config';
import Main from './ui/main';
import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import LoginRoute from './routes/login-route';
import WelcomeAndLogin from './ui/welcome-and-login';
import PrivateRoute from './routes/private-route';
import { msalConfig } from './../authConfig';
import {
  PublicClientApplication,
  EventType,
} from '@azure/msal-browser';
import { MathJaxContext } from 'better-react-mathjax';

const mathJaxConfig = {
  loader: { load: ['[tex]/html'] },
  tex: {
    packages: { '[+]': ['html'] },
    inlineMath: [['$', '$']],
    displayMath: [['$$', '$$']],
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    event.payload.account
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint,
      clientId: config.clientId,
    });

  // console.log('AppRender');

  return (
    <MathJaxContext config={mathJaxConfig}>
      <MsalProvider instance={msalInstance}>
        <StateProvider>
          <BrowserRouter>
            <TeamsFxContext.Provider
              value={{ theme, themeString, teamsUserCredential }}
            >
              <div className="App">
                <React.Suspense fallback={<div />}>
                  <PrivateRoute
                    path="/"
                    name="MainApp"
                    component={Main}
                  />
                  <LoginRoute
                    exact
                    path="/login"
                    name="Login"
                    component={WelcomeAndLogin}
                  />
                </React.Suspense>
              </div>
            </TeamsFxContext.Provider>
          </BrowserRouter>
        </StateProvider>
      </MsalProvider>
    </MathJaxContext>
  );
}
