/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  bots: [
    {
      id: 'isg',
      name: 'OBoS - İş Sağlığı ve Güvenliği Asistanı',
      botGroup: 'ISG Asistanı',
      description: 'ISG Asistanı',
      endPoint: 'https://localhost:3978/api/messages',
      thumbnail: 'https://via.placeholder.com/150',
      color: 'peach',
      initials: 'ISG',
      welcomeMessage: `Merhaba! Kazasız ve sağlıklı günler dilerim. Ben İş Sağlığı ve Güvenliği Asistanı, size nasıl yardımcı olabilirim? \n Aşağıdaki soru örneklerinden faydalanabilirsiniz.
      `,
      active: true,
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BOTS':
      return {
        ...state,
        bots: action.payload,
      };
    default:
      return state;
  }
};
