/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from '@azure/msal-react';
import { useCallback, useContext, useEffect } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useDispatch } from 'react-redux';
import { useData } from '@microsoft/teamsfx-react';
import { app } from '@microsoft/teams-js';
import { loginRequest, tokenRequest } from '../authConfig';
import { useSelector } from 'react-redux';

export default function useAppAuthentication(runLocation) {
  const { instance } = useMsal();
  const userId = useSelector((state) => {
    return state.auth.userId;
  });

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const dispatch = useDispatch();

  const isSignedIn = userId !== undefined;

  const hubName = useData(async () => {
    await app.initialize();
    const context = await app.getContext();
    return context.app.host.name;
  })?.data;

  const appEnvironment = hubName === undefined ? 'web' : 'teams';

  const tryAcquireTokenSilent = useCallback(async () => {
    try {
      if (instance) {
        const activeAccount = await instance.getActiveAccount();

        if (activeAccount && appEnvironment === 'web') {
          const tokenResponse = await instance.acquireTokenSilent(
            tokenRequest
          );

          dispatch({
            type: 'SET_AUTH_USER',
            payload: {
              userId: activeAccount.username,
              displayName: activeAccount.name,
              appEnvironment: 'web',
              accessToken: tokenResponse.accessToken,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [instance, appEnvironment, runLocation]);

  const tryLoginWithTeamsFx = useCallback(
    async (setErrorMessages) => {
      try {
        if (teamsUserCredential) {
          const userInfo = await teamsUserCredential.getUserInfo();
          dispatch({
            type: 'SET_AUTH_USER',
            payload: {
              userId: userInfo.preferredUserName || userInfo.mail,
              displayName: userInfo.displayName,
              appEnvironment: 'teams',
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [teamsUserCredential, appEnvironment]
  );

  const tryLoginWithMSAL = async (setErrorMessages) => {
    try {
      const loginResponse = await instance.loginPopup({
        ...loginRequest,
      });

      if (loginResponse.account) {
        instance.setActiveAccount(loginResponse.account);
        console.log('loginResponse', loginResponse);
      }
      const tokenResponse = await instance.acquireTokenSilent(
        tokenRequest
      );
      console.log('tokenResponse', tokenResponse);
      dispatch({
        type: 'SET_AUTH_USER',
        payload: {
          userId: loginResponse.account.username,
          displayName: loginResponse.account.name,
          appEnvironment: 'web',
          accessToken: tokenResponse.accessToken,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appEnvironment === 'teams') tryLoginWithTeamsFx();
    else tryAcquireTokenSilent();
  }, [appEnvironment, teamsUserCredential, tryLoginWithTeamsFx]);

  return {
    isSignedIn,
    appEnvironment,
    tryLoginWithTeamsFx,
    tryLoginWithMSAL,
  };
}
