import React from 'react';
import {
  Tree,
  TreeItem,
  TreeItemLayout,
  Button,
} from '@fluentui/react-components';
import {
  Compose20Regular,
  Chat16Regular,
} from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { Avatar } from '@fluentui/react-components';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

export default function ChatTimeHistoryChatItem({
  timeGroup,
  chats,
  currentChatId,
  navigateToChat,
  navigateToHome,
}) {
  const dispatch = useDispatch();

  const [showAllChats, setShowAllChats] = React.useState(true);

  const visibleChats = showAllChats ? chats : chats.slice(0, 5);

  if (visibleChats.length === 0) {
    return null;
  }

  return (
    <div className="relative mt-5 first:mt-0 last:mb-5">
      <div className="sticky top-0 z-20 bg-[#fff5ef]">
        <span className="flex h-9 items-center">
          <h3 className="pb-2 pt-3 px-2 text-xs font-semibold text-ellipsis overflow-hidden break-all text-token-text-primary">
            {timeGroup}
          </h3>
        </span>
      </div>
      <ol>
        {visibleChats.map((chat) => {
          const { chatId, subject } = chat;

          const onClickItem = () => {
            navigateToChat(chatId);
          };

          return (
            <li
              key={chatId}
              className="relative z-[15]"
              style={{
                opacity: 1,
                height: 'auto',
                overflow: 'hidden',
              }}
            >
              <div
                className={classNames(
                  'no-draggable group relative rounded-lg active:opacity-90 cursor-pointer hover:bg-[#ffffff]',
                  {
                    'bg-[#ffffff]': chatId === currentChatId,
                  }
                )}
              >
                <div
                  className="flex items-center gap-2 p-2"
                  data-discover="true"
                  onClick={onClickItem}
                >
                  <div
                    className="relative grow overflow-hidden whitespace-nowrap  "
                    dir="auto"
                  >
                    {subject}
                    <div className="absolute bottom-0 top-0 to-transparent ltr:right-0 ltr:bg-gradient-to-l rtl:left-0 rtl:bg-gradient-to-r w-10 from-60%"></div>
                  </div>
                </div>
                <div className="absolute bottom-0 top-0 items-center gap-1.5 pr-2 ltr:right-0 rtl:left-0 flex">
                  <span className="" data-state="closed"></span>
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
