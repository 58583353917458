/* eslint-disable import/no-anonymous-default-export */
// write chatsreducer here
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH_USER': {
      //  console.log('SET_AUTH_USER', action.payload);
      return {
        ...state,
        ...action.payload,
        //   userId: action.payload.userId,
        //   displayName: action.payload.displayName,
        //   apiAccessToken: action.payload.apiAccessToken,
        //   appEnvironment: action.payload.appEnvironment
      };
    }
    default:
      return state;
  }
};
