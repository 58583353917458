import {
  createDarkTheme,
  createLightTheme,
} from '@fluentui/react-components';

import type { BrandVariants } from '@fluentui/react-components';

const isgTheme: BrandVariants = {
  10: '#070201',
  20: '#281308',
  30: '#431D0D',
  40: '#56250D',
  50: '#692F0C',
  60: '#7B390A',
  70: '#8D4505',
  80: '#9E5100',
  90: '#AD6000',
  100: '#BC6F00',
  110: '#CA7E00',
  120: '#D98E00',
  130: '#E69E00',
  140: '#F3AF00',
  150: '#FFC00E',
  160: '#FFD56F',
};

const lightIsgTheme: Theme = {
  ...createLightTheme(isgTheme),
  colorBrandForeground1: isgTheme[110],
  colorBrandForeground2: isgTheme[120],
};

const darkIsgTheme: Theme = {
  ...createDarkTheme(isgTheme),
};

darkIsgTheme.colorBrandForeground1 = isgTheme[110];
darkIsgTheme.colorBrandForeground2 = isgTheme[120];

export { lightIsgTheme, darkIsgTheme };
