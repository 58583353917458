export const joyRideSteps = [
  {
    content: <h2>Hoşgeldiniz! Sizinle kısa bir tura başlayalım!</h2>,
    locale: { skip: <strong aria-label="skip">Turu atla</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: <h2>Yeni bir sohbet başlatmak için buraya tıklayın</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.step-compose',
  },
  {
    content: 'Bu alana yazarak asistana mesaj gönderebilirsiniz.',
    //   placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '#prompt-textarea',
    //  title: 'Asistan Seçimi yapın'
  },
  {
    content:
      'Daha önce yapmış olduğunuz bütün geçmiş yazışmalarınızı buradan görebilirsiniz. En son yaptığınız yazışma en üstte yer alır. ',
    //   placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '#chat-list',
    //  title: 'Asistan Seçimi yapın'
  },
  {
    content:
      'Bu turu istediğiniz zaman burada yer alan menüdeki Turu göster linkine tıklayarak tekrar başlatabilirsiniz. İyi sohbetler!',
    //   placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    placement: 'top',
    target: '#profile-bar',
    //  title: 'Asistan Seçimi yapın'
  },
];
