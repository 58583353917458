/* eslint-disable import/no-anonymous-default-export */
const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CHATS':
            return action.payload;

        case 'CREATE_CHAT':
            return {
                ...state,
                [action.payload.chatId]: {
                    chatId: action.payload.chatId,
                    botId: action.payload.botId,
                    subject: action.payload.subject,
                    messages: [],
                    timestamp: Date.now()
                }
            };
        case 'SET_CHAT_MESSAGES':
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    messages: action.payload.messages,
                    isGenerating: false
                }
            };
        case 'ADD_ASSISTANT_STREAMING_MESSAGE': {
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    messages: [
                        ...state[action.payload.chatId].messages,
                        {
                            role: 'assistant',
                            isStreaming: true,
                            userQuery: action.payload.userQuery,
                            message: action.payload.message,
                            timestamp: Date.now()
                        }
                    ],
                    isGenerating: true,
                    timestamp: Date.now()
                }
            };
        }
        case 'END_ASSISTANT_STREAMING_MESSAGE': {
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    messages: [
                        ...state[action.payload.chatId].messages.slice(0, -1),
                        {
                            role: 'assistant',
                            message: action.payload.message,
                            timestamp: Date.now()
                        }
                    ],
                    isGenerating: false,
                    timestamp: Date.now()
                }
            };
        }
        case 'ADD_USER_MESSAGE': {
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    messages: [
                        ...state[action.payload.chatId].messages,
                        {
                            role: 'user',
                            message: action.payload.message,
                            timestamp: Date.now()
                        }
                    ],
                    timestamp: Date.now()
                }
            };
        }
        case 'START_GENERATING':
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    isGenerating: true
                }
            };
        default:
            return state;
    }
};
