import { useMsal } from '@azure/msal-react';
import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { shift } from '@floating-ui/core';
import { Avatar } from '@fluentui/react-components';
import React, { useId, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfilePopup from './profile-popup';
import { EventType } from '@azure/msal-browser';

export default function ProfileBar() {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { instance } = useMsal();
  const { displayName = '', userId = '' } = useSelector(
    (state) => state.auth
  );

  instance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      dispatch({
        type: 'SET_AUTH_USER',
        payload: { userId: undefined, displayName: undefined },
      });
    }
    // console.log('addEventCallback', event);
  });

  const handleLogout = () => {
    instance
      .logoutPopup({
        mainWindowRedirectUri: '/', // redirects the top level app after logout
        account: instance.getActiveAccount(),
      })
      .catch((error) => console.log(error));
  };

  const handleTourStart = () => {
    dispatch({ type: 'SET_PRODUCT_TOUR_VISIBLE' });
    window.location.reload();
  };

  const { refs, floatingStyles, context } = useFloating({
    // transform: false,
    placement: 'bottom-right', // use bottom-center in mobile-header
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();

  return (
    <div
      type="button"
      ref={refs.setReference}
      {...getReferenceProps()}
      id="radix-:r4p6:"
      aria-haspopup="menu"
      aria-expanded="false"
      data-state="closed"
      className="flex flex-col pt-2 empty:hidden dark:border-white/20"
    >
      <div className="flex w-full items-center juice:md:hidden">
        <div className="max-w-[100%] grow">
          <div className="group relative" data-headlessui-state="">
            <button
              className="flex w-full max-w-[100%] items-center gap-2 rounded-lg p-2 text-sm  hover:bg-[#ececec] group-ui-open:bg-token-sidebar-surface-secondary"
              id="headlessui-menu-button-:re:"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              data-headlessui-state=""
            >
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center overflow-hidden rounded-full">
                  <div className="relative flex">
                    <Avatar
                      color="beige"
                      name={displayName}
                      activeAppearance="ring-shadow"
                      active="active"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-1 -top-px grow -space-y-px truncate text-left text-token-text-primary">
                <div>{displayName}</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <ProfilePopup
            refs={refs}
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            headingId={headingId}
            userId={userId}
            handleLogout={handleLogout}
            handleTourStart={handleTourStart}
          />
        </FloatingFocusManager>
      )}
    </div>
  );
}
