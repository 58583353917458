import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import BotSelectorTitle from './bot-selector-title';
import HeaderComposeButton from './header-compose-button';
import ChatHistoryNav from './chat-history-nav';
import { lightIsgTheme } from './../../theme';
import { FluentProvider } from '@fluentui/react-components';

export default function MobileHeader() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    console.log('open');
    setIsOpen(true);
  }

  function onModalOpen() {
    console.log('onModalOpen');
    setIsOpen(true);
  }

  return (
    <div className="sticky top-0 z-10 flex min-h-[40px] items-center justify-center border-b border-token-border-medium bg-[#ffffff] pl-1 md:hidden">
      <button
        type="button"
        onClick={onModalOpen}
        className="absolute bottom-0 left-0 top-0 inline-flex items-center justify-center rounded-md px-3 hover:text-token-text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:opacity-50"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      {/* <BotSelectorTitle /> */}
      <div className="absolute bottom-0 right-0 top-0 flex items-center">
        <HeaderComposeButton />
      </div>
      <div className="hidden md:block">
        <Transition show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 flex">
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white translate-x-0">
                <Transition.Child
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20 bg-white">
                    <FluentProvider theme={lightIsgTheme}>
                      <ChatHistoryNav closeModal={closeModal} />
                    </FluentProvider>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
