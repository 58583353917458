/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import version from version.json
import Version from '../../version.json';

export default function ProfilePopup({
  refs,
  floatingStyles,
  getFloatingProps,
  headingId,
  userId,
  handleLogout,
  handleTourStart,
}) {
  return (
    <div
      className="popover mb-1 w-full overflow-hidden z-50
            rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#fff] p-1.5
            shadow-lg outline-none opacity-100 translate-y-0"
      ref={refs.setFloating}
      style={floatingStyles}
      //     aria-labelledby={headingId}
      {...getFloatingProps()}
    >
      <nav role="none">
        <div
          className="ml-3 mr-2 py-2 text-sm text-token-text-secondary"
          role="none"
        >
          Versiyon : {Version.version}
        </div>
        <div
          className="h-px bg-[rgba(0,0,0,0.1)] my-1.5"
          role="none"
        ></div>
        <div
          className="ml-3 mr-2 py-2 text-sm text-token-text-secondary"
          role="none"
        >
          {userId}
        </div>
        <div
          className="h-px bg-[rgba(0,0,0,0.1)] my-1.5"
          role="none"
        ></div>
        <div
          className="ml-3 mr-2 py-2 text-sm text-token-text-secondary"
          role="none"
        >
          <a
            as="button"
            className="cursor-pointer"
            onClick={handleTourStart}
          >
            Turu göster
          </a>
        </div>
        <div
          className="h-px bg-[rgba(0,0,0,0.1)] my-1.5"
          role="none"
        ></div>
        <a
          as="button"
          className="flex gap-2 rounded p-2.5 text-sm cursor-pointer
            focus:ring-0 radix-disabled:pointer-events-none radix-disabled:opacity-50
            group items-center hover:bg-[#ececec]"
          id="headlessui-menu-item-:rc8:"
          role="menuitem"
          tabindex="-1"
          data-headlessui-state=""
          onClick={handleLogout}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-md"
          >
            <path
              d="M11 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H11"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <path
              d="M20 12H11M20 12L16 16M20 12L16 8"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          Çıkış yap
        </a>
      </nav>
    </div>
  );
}
