import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Compose24Regular } from '@fluentui/react-icons';
import ChatHistoryNavBotGroup from './chat-history-nav-bot-group';
import ProfileBar from './profile-bar';
import ChatHistoryTree from './chat-history-tree';
import ChatTimeHistory from './chat-time-history';

const months = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];

function groupByTimeFrame(data) {
  // Helper function to format date without time
  const formatDate = (date) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  };

  // Get current date
  const now = new Date();
  const today = formatDate(now);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - today.getDay() - 7);

  const lastMonthStart = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );

  // Function to determine the time frame
  const getTimeFrame = (date) => {
    const formattedDate = formatDate(date);

    if (formattedDate.getTime() === today.getTime()) {
      return 'Bugün';
    } else if (formattedDate.getTime() === yesterday.getTime()) {
      return 'Dün';
    } else if (formattedDate >= lastWeekStart) {
      return 'Geçen Hafta';
    } else if (formattedDate >= lastMonthStart) {
      // return 'Geçen Ay';
      return months[date.getMonth()];
    } else {
      const monthsAgo =
        now.getMonth() -
        date.getMonth() +
        12 * (now.getFullYear() - date.getFullYear());
      // return `${monthsAgo} Ay Önce`;
      return months[date.getMonth()];
    }
  };

  // Sorting and grouping
  const sortedData = data.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );
  const grouped = {};

  sortedData.forEach((item) => {
    const date = new Date(item.timestamp);
    const timeFrame = getTimeFrame(date);
    if (!grouped[timeFrame]) {
      grouped[timeFrame] = [];
    }
    grouped[timeFrame].push(item);
  });

  return grouped;
}

export default function ChatHistoryNav({ closeModal }) {
  const history = useHistory();

  const { params: { chatId: currentChatId } = {} } =
    useRouteMatch('/chat/:chatId') || {};

  // write a selector function that takes state.chats, order them by time and group them by time frame such as today, yesterday, last week, last month, n months ago
  const chatTimeGroups = useSelector((state) => {
    const _chats = Object.keys(state.chats).map(
      (chatId) => state.chats[chatId]
    );
    // create a for loop to generate 1000 chat items from today to 1000 days ago
    // const _chats = [];
    // const now = new Date();
    // for (let i = 0; i < 1000; i++) {
    //   const date = new Date(now);
    //   date.setDate(now.getDate() - i);
    //   _chats.push({
    //     chatId: i,
    //     timestamp: date.toISOString(),
    //     subject: `Chat ${i} - ${date.toISOString()}`,
    //   });
    // }

    return groupByTimeFrame(_chats);
  });

  const navigateToHome = useCallback(() => {
    console.log('navigating to home');
    history.push('/');
    if (closeModal) closeModal();
  }, [history, closeModal]);

  const navigateToChat = useCallback(
    (chatId) => {
      console.log('navigating to chat', chatId);
      history.push(`/chat/${chatId}`);
      if (closeModal) closeModal();
    },
    [history, closeModal]
  );

  return (
    <nav className="flex h-full w-full flex-col px-3 pb-3.5">
      <div className="flex-col flex-1 transition-opacity duration-500 -mr-2 pr-2 overflow-y-auto">
        <div
          onClick={navigateToHome}
          className="sticky left-0 right-0 top-0 z-20 pt-3.5"
        >
          <div className="pb-0.5 last:pb-0" tabIndex="0">
            <div className="group flex h-10 items-center gap-2 rounded-lg bg-[##ffede3] px-2 font-medium cursor-pointer hover:bg-[#ececec]">
              <div className="h-7 w-7 flex-shrink-0">
                <div className="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full bg-token-main-surface-primary text-token-text-primary">
                  <img src="/isg_logo.png" alt="isg" />
                </div>
              </div>
              <div className="grow overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold">
                OBoS - İş Sağlığı ve Güvenliği Asistanı
              </div>
              <div className="step-compose flex gap-3">
                <span
                  className="flex items-center"
                  data-state="closed"
                >
                  <button className="text-token-text-primary">
                    <Compose24Regular />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div>Shortcuts</div> */}
        <div
          id="chat-list"
          className="flex flex-col gap-2 pb-2 text-sm overflow-y-auto h-full"
        >
          <div className="empty:hidden">
            {/* {bots.map((bot) => {
                            return (
                                <ChatHistoryNavBotGroup
                                    bot={bot}
                                    currentChatId={currentChatId}
                                    navigateToChat={navigateToChat}
                                />
                            );
                        })} */}
            <ChatTimeHistory
              chatTimeGroups={chatTimeGroups}
              currentChatId={currentChatId}
              navigateToChat={navigateToChat}
              navigateToHome={navigateToHome}
            />
          </div>
        </div>
        {/* <div className="flex flex-col pt-2 empty:hidden dark:border-white/20">
          Alt butonlar
        </div> */}
      </div>
      <div id="profile-bar">
        <ProfileBar />
      </div>
    </nav>
  );
}
