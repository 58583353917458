import React from 'react';
import { SendRegular } from '@fluentui/react-icons';
import { Avatar } from '@fluentui/react-components';
import useAppAuthentication from '../../helpers/useAppAuthentication';
import Version from '../../version.json';

const questionSets = [
  [
    'Ekipman arızaları nasıl tespit edilmelidir?',
    'Şarj düşümü bir arıza mıdır?',
    'Planlı duruş süresi uzarsa ne yapılmalı?',
    'Sık arıza veren ekipmana nasıl müdahale edilmelidir?',
    'Arızalı ekipman nasıl raporlanır?',
    'Ekipman performansı nasıl izlenir?',
    'Bakım öncelikleri nasıl belirlenir?',
    'Yeni ekipman kontrolü nasıl yapılır?',
    'Beklenmedik arızalar nasıl yönetilir?',
    'Aşınma belirtileri nasıl tespit edilir?',
  ],
];

const COLORS = [
  '#bbf7d0',
  '#99f6e4',
  '#bfdbfe',
  '#ddd6fe',
  '#f5d0fe',
  '#fed7aa',
  '#fee2e2',
];
const TAGS = [
  {
    text: 'Ekipman arızaları nasıl tespit edilmelidir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Şarj düşümü bir arıza mıdır?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Planlı duruş süresi uzarsa ne yapılmalı?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Sık arıza veren ekipmana nasıl müdahale edilmelidir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Arızalı ekipman nasıl raporlanır?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Ekipman performansı nasıl izlenir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Bakım öncelikleri nasıl belirlenir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Yeni ekipman kontrolü nasıl yapılır?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Beklenmedik arızalar nasıl yönetilir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Aşınma belirtileri nasıl tespit edilir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Şarj düşümü bir arıza mıdır?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Planlı duruş süresi uzarsa ne yapılmalı?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Sık arıza veren ekipmana nasıl müdahale edilmelidir?',
    botname: 'ISG Asistan',
  },
  {
    text: 'Arızalı ekipman nasıl raporlanır?',
    botname: 'ISG Asistan',
  },
];

const QUESTION_SETS = [
  {
    botName: 'Bakım Planlama',
    question: 'Planlı bakım süresi nasıl belirlenir?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Bakım faaliyetleri nasıl planlanmalıdır?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Bakım maliyetlerini nasıl düşürebilirim?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Bakım ekiplerinin performansı nasıl değerlendirilir?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Bakım planlamasında hangi yazılımlar kullanılabilir?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Planlı bakım sırasında hangi kontroller yapılmalıdır?',
  },
  {
    botName: 'Bakım Planlama',
    question:
      'Bakım planlaması yaparken hangi veriler kullanılmalıdır?',
  },
  {
    botName: 'Bakım Planlama',
    question: 'Bakım planlamasında yaşanan sıkıntılar nasıl çözülür?',
  },
  {
    botName: 'Bakım Planlama',
    question:
      'Bakım ekipmanlarının önceliklendirilmesi nasıl yapılır?',
  },
  {
    botName: 'Bakım Planlama',
    question:
      'Bakım planlamasında kullanılan anahtar performans göstergeleri nelerdir?',
  },

  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların bakımı nasıl yapılır?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipman arızaları nasıl tespit edilir?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların ömrünü uzatmak için ne yapılmalı?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların performansı nasıl izlenir?',
  },
  {
    botName: 'Döner Ekipman',
    question:
      'Döner ekipmanlarda kullanılan yağlar nasıl seçilmelidir?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipman bakımı için hangi araçlar gereklidir?',
  },
  {
    botName: 'Döner Ekipman',
    question:
      'Döner ekipmanlarda sık karşılaşılan problemler nelerdir?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların vibrasyon kontrolü nasıl yapılır?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların montajı nasıl yapılmalıdır?',
  },
  {
    botName: 'Döner Ekipman',
    question: 'Döner ekipmanların balans ayarı nasıl yapılır?',
  },

  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliği nasıl artırılır?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'Güvenilirlik analizleri nasıl yapılır?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliği raporları nasıl hazırlanır?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliğinde kritik noktalar nelerdir?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliği standartları nelerdir?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'Güvenilirlik testleri nasıl yapılır?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliğini etkileyen faktörler nelerdir?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'Güvenilirlik yönetiminde kullanılan araçlar nelerdir?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question: 'İşletme Güvenirliği için eğitimler nasıl düzenlenir?',
  },
  {
    botName: 'İşletme Güvenirliği',
    question:
      'İşletme Güvenirliği ile bakım planlaması nasıl ilişkilendirilir?',
  },

  {
    botName: 'Proje Kalite Kontrol',
    question: 'Proje kalite kontrol süreçleri nelerdir?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Kalite kontrol planı nasıl oluşturulur?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Kalite kontrol testleri nasıl yapılır?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Proje kalite kontrol raporları nasıl hazırlanır?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Kalite kontrol için hangi araçlar kullanılır?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Proje kalite standartları nelerdir?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question:
      'Kalite kontrol süreçlerinde yaşanan problemler nasıl çözülür?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question:
      'Kalite kontrol ile proje yönetimi nasıl entegre edilir?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Kalite kontrol eğitimleri nasıl düzenlenir?',
  },
  {
    botName: 'Proje Kalite Kontrol',
    question: 'Kalite kontrol denetimleri nasıl yapılır?',
  },

  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların bakımı nasıl yapılır?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipman arızaları nasıl tespit edilir?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların ömrünü uzatmak için ne yapılmalı?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların performansı nasıl izlenir?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların kontrolü nasıl yapılır?',
  },
  {
    botName: 'Statik Ekipman',
    question:
      'Statik ekipmanlarda kullanılan malzemeler nasıl seçilir?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların montajı nasıl yapılmalıdır?',
  },
  {
    botName: 'Statik Ekipman',
    question:
      'Statik ekipmanlarda sık karşılaşılan problemler nelerdir?',
  },
  {
    botName: 'Statik Ekipman',
    question: 'Statik ekipmanların bakım planı nasıl yapılır?',
  },
  {
    botName: 'Statik Ekipman',
    question:
      'Statik ekipmanların performans testleri nasıl yapılır?',
  },

  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontroller nasıl yapılır?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrol prosedürleri nelerdir?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrol raporları nasıl hazırlanır?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrollerde hangi araçlar kullanılır?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrollerde yaşanan sorunlar nasıl çözülür?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrol planlaması nasıl yapılır?',
  },
  {
    botName: 'Teknik Kontrol',
    question:
      'Teknik kontrol süreçlerinde dikkat edilmesi gerekenler nelerdir?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrol eğitimleri nasıl düzenlenir?',
  },
  {
    botName: 'Teknik Kontrol',
    question:
      'Teknik kontrol ile kalite kontrol nasıl ilişkilendirilir?',
  },
  {
    botName: 'Teknik Kontrol',
    question: 'Teknik kontrollerde kullanılan yazılımlar nelerdir?',
  },

  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulamalar nasıl yapılır?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulama planı nasıl hazırlanır?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulamalarda kullanılan araçlar nelerdir?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulama raporları nasıl hazırlanır?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulamalarda yaşanan sorunlar nasıl çözülür?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulama eğitimleri nasıl düzenlenir?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulama süreçleri nasıl yönetilir?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulamalarda güvenlik önlemleri nelerdir?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulama projeleri nasıl değerlendirilir?',
  },
  {
    botName: 'Teknik Uygulamalar',
    question: 'Teknik uygulamalarda kalite kontrol nasıl sağlanır?',
  },

  {
    botName: 'Elektrik',
    question: 'Elektrik arızaları nasıl tespit edilir?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik ekipmanlarının bakımı nasıl yapılır?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik tesisat kontrolü nasıl yapılır?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik arızalarının önlenmesi için ne yapılmalı?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik güvenlik önlemleri nelerdir?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik ekipmanlarının ömrü nasıl uzatılır?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik bakım planı nasıl hazırlanır?',
  },
  {
    botName: 'Elektrik',
    question:
      'Elektrik sistemlerinde sık karşılaşılan problemler nelerdir?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik tesisatı yenileme nasıl yapılır?',
  },
  {
    botName: 'Elektrik',
    question: 'Elektrik bakımında kullanılan araçlar nelerdir?',
  },

  {
    botName: 'Enstrüman',
    question: 'Enstrüman kalibrasyonu nasıl yapılır?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman arızaları nasıl tespit edilir?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman bakımı nasıl yapılır?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrümanların performansı nasıl izlenir?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrümanların ömrünü uzatmak için ne yapılmalı?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman montajı nasıl yapılır?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman bakım planı nasıl hazırlanır?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman performans testleri nasıl yapılır?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrümanlarda sık karşılaşılan problemler nelerdir?',
  },
  {
    botName: 'Enstrüman',
    question: 'Enstrüman kalibrasyon süreçleri nelerdir?',
  },

  {
    botName: 'HR Asistanı',
    question: 'Yeni çalışanların oryantasyonu nasıl yapılır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Çalışan performans değerlendirmesi nasıl yapılır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'İşe alım süreci nasıl yönetilir?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Çalışan eğitim programları nasıl hazırlanır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Çalışan motivasyonu nasıl artırılır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'İşten çıkarma süreci nasıl yönetilir?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Performans geribildirimi nasıl verilir?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Çalışan şikayetleri nasıl ele alınır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'İş gücü planlaması nasıl yapılır?',
  },
  {
    botName: 'HR Asistanı',
    question: 'Çalışan bağlılığı nasıl sağlanır?',
  },
];

const DURATION = 45000;
const ROWS = 5;
const TAGS_PER_ROW = 9;

const random = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

const InfiniteLoopSlider = ({
  children,
  duration,
  reverse = false,
}) => {
  return (
    <div
      className="loop-slider"
      style={{
        '--duration': `${duration}ms`,
        //  '--direction': reverse ? 'reverse' : 'normal'
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({ text, botName }) => (
  <div>
    <div className="relative bottom-[2px] left-0 flex-row flex items-center">
      <Avatar
        color="colorful"
        name={botName}
        //color={COLORS[Math.floor(Math.random() * COLORS.length)]}
        //  initials={initials}
        activeAppearance="ring-shadow"
      />
      <div className="ml-1 text-gray-600">{botName}</div>
    </div>
    <div className="tag">
      {text}
      <div className="items-center justify-center flex">
        <SendRegular />
      </div>
    </div>
  </div>
);

export default function WelcomeAndLogin() {
  const { appEnvironment, tryLoginWithMSAL, tryLoginWithTeamsFx } =
    useAppAuthentication('WelcomeAndLogin');

  console.log('WelcomeAndLogin', appEnvironment);
  const onClickLogin = () => {
    if (appEnvironment === 'web') {
      tryLoginWithMSAL();
    } else {
      tryLoginWithTeamsFx();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="flex flex-col items-center gap-2 rounded-lg px-2 font-medium cursor-pointer">
        <div className="h-48 w-48 flex-shrink-0">
          <div
            className="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full
                    bg-token-main-surface-primary text-token-text-primary"
          >
            <img src="/isg_logo.png" alt="isg" />
          </div>
        </div>
        <div className="grow text-ellipsis whitespace-nowrap text-[30px] font-bold">
          OBoS - İş Sağlığı ve Güvenliği Asistanı
        </div>
      </div>
      {/* <div className="tag-list">
        {[...new Array(ROWS)].map((_, i) => (
          <InfiniteLoopSlider
            key={i}
            duration={random(DURATION - 5000, DURATION + 5000)}
            reverse={i % 2}
          >
            {shuffle(QUESTION_SETS)
              .slice(0, TAGS_PER_ROW)
              .map((tag) => (
                <Tag
                  text={tag.question}
                  botName={tag.botName}
                  key={tag}
                />
              ))}
          </InfiniteLoopSlider>
        ))}
        <div className="fade" />
      </div> */}

      {/* <div id="scrolling-ideas" className="w-full mt-8">
                {questionSets.map((questionSet, index) => {
                    return (
                        <div className="flex flex-row mb-3">
                            {questionSet.map((question, index) => (
                                <div className="bg-slate-100 h-9 p-2 rounded-sm flex-row flex justify-between gap-3 items-center mr-2">
                                    <div className="whitespace-nowrap overflow-hidden">{question}</div>
                                    <div>
                                        <SendRegular />
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div> */}
      <div className="p-8 rounded items-center justify-center flex flex-col">
        <button
          className="bg-[#CA7E00] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={onClickLogin}
        >
          Giriş yap
        </button>
        <div className="mt-4 text-center text-gray-600">
          v{Version.version}
        </div>
        <div className="mt-4 text-center text-gray-600">
          Giriş yaparak ISG Asistanı ile sohbet etmeye
          başlayabilirsiniz.
        </div>
        <div>
          <a
            href="https://www.turktraktor.com.tr/"
            target="_blank"
            rel="noreferrer"
          >
            Türk Traktör Resmi Web Sitesi
          </a>
        </div>
      </div>
    </div>
  );
}
