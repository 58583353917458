const config = {
  aadAppTenantId:
    process.env.NODE_ENV === 'production'
      ? '2b099b4a-7168-44a8-a734-f3ca195944ab'
      : 'c490a67c-dea0-4aaa-ae0f-f99bae3aceb9', // process.env.AAD_APP_TENANT_ID,
  initiateLoginEndpoint:
    process.env.NODE_ENV === 'production'
      ? 'https://blue-forest-067864603.5.azurestaticapps.net/auth-start.html'
      : 'http://localhost:3000/auth-start.html', //process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId:
    process.env.NODE_ENV === 'production'
      ? '2c8b56d6-6bef-4486-a17e-f6e1ac06063c'
      : '4f61a06f-a198-440f-8ccc-05e4d46ae7c9', //process.env.REACT_APP_CLIENT_ID,
  //apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  //apiName: process.env.REACT_APP_FUNC_NAME,
};

export default config;
