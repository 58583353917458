import React from 'react';
import ChatHistoryNav from './chat-history-nav';
import ProfileBar from './profile-bar';

export default function LeftPanel() {
  return (
    <div
      className="flex-shrink-0 overflow-x-hidden bg-[#fff5ef] hidden md:block"
      style={{ width: 440 }}
    >
      <div className="h-full w-[440px]">
        <div className="flex h-full min-h-0 flex-col">
          <div className="flex h-full min-h-0 flex-col">
            <div className="scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20">
              <ChatHistoryNav />
            </div>
          </div>
        </div>
      </div>
      {/* <ProfileBar /> */}
      {/* <Welcome showFunction={showFunction} /> */}
    </div>
  );
}
