import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { FluentProvider } from '@fluentui/react-components';
import './index.css';
import { lightIsgTheme } from './theme';

console.log('Environment : ', process.env.NODE_ENV);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <FluentProvider theme={lightIsgTheme}>
    <App />
  </FluentProvider>
);
