/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  currentBotId: 'isg',
  currentScreenMode: 'desktop',
  showProductTour: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_BOT':
      return {
        ...state,
        currentBotId: action.payload.botId,
      };
    case 'SET_CURRENT_SCREEN_MODE':
      return {
        ...state,
        currentScreenMode: action.payload.screenMode,
      };

    case 'SET_PRODUCT_TOUR_SHOWN':
      return {
        ...state,
        showProductTour: false,
      };
    case 'SET_PRODUCT_TOUR_VISIBLE':
      return {
        ...state,
        showProductTour: true,
      };
    default:
      return state;
  }
};
